<template>
  <div class="preview">
    <van-nav-bar
      title="事故预览"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="previewcon">
      <div class="topcon">
        <div class="name">{{ accidentForm.name }}</div>
        <div class="code">事故编码：{{ accidentForm.code }}</div>
      </div>
      <div class="bodycon">
        <div class="block">
          <div class="blocktitle">基本信息</div>
          <div class="item">
            <div class="itemlabel">事发时间：</div>
            <div class="itemvalue">{{ accidentForm.startTime }}</div>
          </div>
          <div class="item">
            <div class="itemlabel">事发地点：</div>
            <div class="itemvalue">{{ accidentForm.place }}</div>
          </div>
          <div class="item">
            <div class="itemlabel">事故类型：</div>
            <div class="itemvalue">{{ accidentForm.accidentTypeName }}</div>
          </div>
          <div class="item">
            <div class="itemlabel">是否应急演练：</div>
            <div class="itemvalue">
              {{ ["否", "是"][accidentForm.isDrill] }}
            </div>
          </div>
          <div class="item">
            <div class="itemlabel">事故危险物质：</div>
            <div class="itemvalue">{{ accidentForm.dangerousName }}</div>
          </div>
          <div class="item">
            <div class="itemlabel">人员伤亡：</div>
            <div class="itemvalue">{{ accidentForm.deathNum || 0 }}</div>
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">事故描述</div>
          <div class="content">
            {{ accidentForm.description }}
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">备注</div>
          <div class="content">
            {{ accidentForm.remark }}
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">附件</div>
          <div class="content">
            <FileUploader
              v-model="accidentForm.fileList"
              :max-count="1"
              :unable-upload="false"
              :accept="FILE_ACCEPT_TYPES.FILE"
              @change="fileUploadEmit"
            />
          </div>
        </div>
        <div class="btn submit" @click="onSubmit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import api from "./api";
import { Toast } from "vant";
export default {
  name: "Preview",
  components: {
    FileUploader
  },
  data() {
    return {
      accidentForm: {},
      fileList: [],
      FILE_ACCEPT_TYPES
    };
  },
  created() {
    console.log(this.$route.query);
    let data = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.accidentForm = data;
    console.log(data);
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    // 上传附件返回值
    fileUploadEmit(val) {
      this.accidentForm.attachmentId = val ? val.id : "";
      this.accidentForm.attachmentName = val ? val.name : "";
    },
    onSubmit() {
      this.accidentForm.status = "1";
      this.$store.commit("setGeo", null);
      api.addAccident(this.accidentForm).then(data => {
        Toast("提交成功");
        history.go(-2);
        this.$store.commit("setSaveAccidentReportId", "");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.preview {
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .van-nav-bar {
    background: linear-gradient(118deg, #62a6f8 0%, #1677ff 100%);
  }
  .previewcon {
    .topcon {
      height: 102px;
      background: linear-gradient(118deg, #62a6f8 0%, #1677ff 100%);
      padding-left: 10px;
      padding-top: 18px;
      .name {
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 8px;
      }
      .code {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .bodycon {
      background: #f7f8fa;
      box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
      border-radius: 8px 8px 0px 0px;
      margin-top: -12px;
      height: calc(100vh - 152px);
      overflow: auto;
      padding: 14px 10px 100px;
      .block {
        padding: 10px;
        background: #ffffff;
        border-radius: 6px;
        margin-bottom: 14px;
        .blocktitle {
          font-size: 16px;
          color: #3b4664;
          font-weight: 500;
        }
        .content {
          line-height: 18px;
          font-size: 12px;
          padding: 10px;
        }
        .item {
          display: flex;
          margin-top: 10px;
          .itemlabel {
            font-size: 14px;
            color: #3b4664;
            opacity: 0.6;
          }
          .itemvalue {
            font-size: 12px;
            color: #3b4664;
          }
        }
      }
      .btn {
        border-radius: 22px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        text-align: center;
        background: linear-gradient(180deg, #1677ff 0%, #62a6f8 100%);
        color: #ffffff;
        margin-top: 36px;
      }
    }
  }
}
</style>
